/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://reinvent.awsevents.com/"
  }, "AWS re:Invent 2022 | Amazon Web Services")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?v=_W_wNNru_ZQ"
  }, "AWS re:Invent 2022 5K")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.megazone.com/reinvent2022-keynote-1129-1/"
  }, "AWS re:Invent 2022 Keynote Report #1 – Monday Night Live with Peter DeSantis")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://holidayinnclub.com/explore-resorts/desert-club"
  }, "Desert Club, Las Vegas Resort | HolidayInnClub.com")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.hilton.com/en/hotels/rslvhvh-resorts-world-las-vegas/"
  }, "Resorts World Las Vegas | Hilton Hotels on the Las Vegas Strip")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.wholefoodsmarket.com"
  }, "Whole Foods Market | Whatever Makes You Whole")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.168markets.com/index-3-NV.html"
  }, "168 Market, Nevada")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twitter.com/LVMPD"
  }, "LVMPD")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twitter.com/LVMPD/status/1598114228405039104"
  }, "라스베가스 행사장 통제 관련 라스베가스 경찰 트윗")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://awskrug.github.io/"
  }, "AWSKRUG - AWS 한국사용자모임")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://martingarrix.com/"
  }, "Martin Garrix | Official Website")), "\n");
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
